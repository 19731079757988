'use client';

import React from 'react';
import { Box, Text } from '@chakra-ui/react';

import Card from 'libs/global/components/Card';
import Divider from 'libs/global/components/Divider';
import { background } from 'libs/utils/colors';
import { useWindowSize } from 'libs/global/hooks/useWindowSize';

import ChatAnchor from 'libs/global/components/ChatAnchor';

const LockIcon = '/icons/icon-lock.svg';

const PAGE_BREAK_WIDTH = 800;

const HelpBox = () => {
    return (
        <Box maxW="sm" fontSize="sm" margin="auto" borderWidth="1px" padding={4}>
            Need help? Please contact your sales lead directly.
            <br />
            Or, our live support is available <ChatAnchor>here</ChatAnchor>.
        </Box>
    );
};

const SecurityFooter = () => {
    return (
        <div className="pv3" style={{ backgroundColor: background, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img src={LockIcon} role="presentation" alt="Lock icon" />
            <Text mx={2} fontWeight="thin">
                Your security is important to us
            </Text>
        </div>
    );
};

interface OnboardingLayoutProps {
    content?: React.ReactNode;
    contentAside?: React.ReactNode;
    contentAsideWidth?: string;
    footer?: React.ReactNode;
    header?: React.ReactNode;
    precontent?: React.ReactNode;
    securityFooter?: boolean;
    subcontent?: React.ReactNode;
}

const WideLayout = ({ header, precontent, content, contentAside, contentAsideWidth, subcontent, securityFooter, footer }: OnboardingLayoutProps) => {
    return (
        <div
            className="mb4"
            style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: 60,
                maxWidth: 1080,
                paddingLeft: 40,
                paddingRight: 40,
                width: '100%',
                boxSizing: 'border-box',
            }}>
            <div className="mb5" style={{ textAlign: 'center' }}>
                {header}
            </div>

            {precontent && (
                <div className="mb3" style={{ textAlign: 'center' }}>
                    {precontent}
                </div>
            )}

            {content && (
                <Card.Base
                    style={{
                        marginBottom: 20,
                    }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Card.Body type="primary" style={{ flex: '1 0 0' }}>
                            {content}
                        </Card.Body>

                        {contentAside && [
                            <div key="divider" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignSelf: 'stretch' }}>
                                <Divider vertical={true} style={{ height: '70%' }} />
                            </div>,
                            <Card.Body key="content-aside" type="primary" style={{ flex: `0 0 ${contentAsideWidth}` }}>
                                {contentAside}
                            </Card.Body>,
                        ]}
                    </div>

                    {securityFooter && <SecurityFooter />}
                </Card.Base>
            )}

            {subcontent}

            <HelpBox />

            {footer && <div className="mt6">{footer}</div>}
        </div>
    );
};

const CompactLayout = ({
    header,
    content,
    contentAside,
    securityFooter,
    subcontent,
    footer,
}: Omit<OnboardingLayoutProps, 'contentAsideWidth' | 'precontent'>) => {
    const spacing = 30;
    return (
        <div className="mb3">
            <div
                style={{
                    width: '100%',
                    textAlign: 'center',
                    marginBottom: spacing,
                    marginTop: spacing,
                    paddingLeft: spacing,
                    paddingRight: spacing,
                    boxSizing: 'border-box',
                }}>
                {header}
            </div>

            {content && (
                <Card.Base className="mh2">
                    <Card.Body>{content}</Card.Body>

                    {contentAside && [
                        <div key="divider" className="mv3" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignSelf: 'stretch' }}>
                            <Divider style={{ width: '70%' }} />
                        </div>,
                        <Card.Body key="content-aside" type="primary">
                            {contentAside}
                        </Card.Body>,
                    ]}

                    {securityFooter && <SecurityFooter />}
                </Card.Base>
            )}

            {subcontent}

            <HelpBox />

            {footer && <div className="mt3">{footer}</div>}
        </div>
    );
};

const OnboardingLayout = ({ contentAsideWidth, ...props }: OnboardingLayoutProps) => {
    const windowSize = useWindowSize();
    contentAsideWidth ||= '40%';

    return (
        <div>
            {windowSize.width && windowSize.width > PAGE_BREAK_WIDTH ? (
                <WideLayout contentAsideWidth={contentAsideWidth} {...props} />
            ) : (
                <CompactLayout {...props} />
            )}
        </div>
    );
};

export default OnboardingLayout;
