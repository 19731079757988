import React from 'react';
import classnames from 'classnames';
import { hexToRgba } from 'libs/wheelhouse_design/utils/color-conversion';
import { grayFaded } from 'libs/utils/colors';

interface CardProps {
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    type?: 'primary';
}

const Header = (props: Pick<CardProps, 'style' | 'children'>) => {
    const styles = {
        base: {
            borderBottom: '1px solid #E2E5EE',
            fontWeight: 500,
            fontSize: 20,
            padding: 15,
        },

        primary: {
            padding: '15px 30px',
        },
    };

    return <div style={{ ...styles.base, ...props.style }}>{props.children}</div>;
};

const Body = (props: CardProps) => {
    const styles = {
        base: {
            flexGrow: 1,
        },
    };

    const classes = {
        primary: 'pa3-s pa4-ns',
    };

    return (
        <div className={classnames('pa3', classes[props.type || ''], props.className)} style={{ ...styles.base, ...props.style }}>
            {props.children}
        </div>
    );
};

const Base = (props: CardProps) => {
    const children = React.Children.map(props.children, (child: React.ReactElement<any>) => {
        // I'm not sure why but on all the children are formed correctly.  Check for props to make sure.
        if (!child || !child.props) {
            return child;
        }
        return React.cloneElement(child, {
            type: props.type,
        });
    });

    return (
        <div
            className={props.className}
            style={{
                backgroundColor: '#fff',
                borderRadius: 2,
                border: `solid 1px ${hexToRgba(grayFaded, 0.5)}`,
                display: 'flex',
                flexDirection: 'column',
                ...props.style,
            }}>
            {children}
        </div>
    );
};

/**
 * @deprecated use chakra-ui instead.
 */
export default {
    Base,
    Body,
    Header,
};
