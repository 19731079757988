'use client';

import React, { useEffect, useRef, useState } from 'react';
import { submitRegistration } from 'libs/global/actions/Site/user';
import Anchor from 'libs/global/components/Anchor';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormHelperText,
    FormLabel,
    FormLabelProps,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    RadioProps,
    Stack,
    Text,
    Tooltip,
    useRadio,
    useRadioGroup,
} from '@chakra-ui/react';
import humanname from 'humanname';
import { grayMedium } from 'libs/utils/colors';
import analytics from 'libs/utils/analytics';
import { useRouter } from 'next/router';
import { privacyPolicyPath, termsOfUsePath } from 'libs/routes';
import { Question } from 'phosphor-react';
import { useAppDispatch } from '../hooks';
import { TurnstileToken } from 'libs/utils/turnstile';
import type { TurnstileInstance } from '@marsidev/react-turnstile';

interface InputFieldProps {
    error?: string;
    label: string;
    children: React.ReactNode;
    tooltip?: string;
    subText?: string;
    style?: FormLabelProps;
}
const InputField = ({ error, label, children, tooltip, subText, style }: InputFieldProps) => {
    return (
        <FormControl isInvalid={!!error}>
            {tooltip ? (
                <Flex gap={1} alignItems="center" mb={1}>
                    <FormLabel mr={0} mb={0} {...style}>
                        {label}
                    </FormLabel>
                    <Tooltip label={tooltip}>
                        <Icon color="gray.300" w={4} h={4} as={Question} />
                    </Tooltip>
                </Flex>
            ) : (
                <FormLabel {...style} mb={1}>
                    {label}
                </FormLabel>
            )}

            {children}
            {subText && !error ? <FormHelperText mt={1.5}>{subText}</FormHelperText> : null}
            {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
        </FormControl>
    );
};

// 1. Create a component that consumes the `useRadio` hook
function RadioCard(props: RadioProps) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();

    return (
        <Box as="label" flex={1}>
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                _checked={{
                    bg: 'purple.50A',
                    color: 'purple.500',
                    fontWeight: 500,
                    borderColor: 'purple.400',
                    boxShadow: 'none',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={5}
                py={3}>
                {props.children}
            </Box>
        </Box>
    );
}
// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
interface PortfolioSizeProps {
    onChange: (value: string) => void;
    value: string;
}
const PortfolioSize = ({ onChange, value }: PortfolioSizeProps) => {
    const options = ['0-9', '10-49', '50-99', '100+'];

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'portfolioSize',
        defaultValue: value,
        onChange,
    });

    const group = getRootProps();

    return (
        <Stack spacing={1}>
            <Text fontWeight={500}>How many properties do you manage?</Text>
            <Flex {...group} w="full" gap={2}>
                {options.map((value) => {
                    const radio = getRadioProps({ value });
                    return (
                        <RadioCard key={value} {...radio}>
                            {value}
                        </RadioCard>
                    );
                })}
            </Flex>
        </Stack>
    );
};

interface RegisterFormProps {
    submitButtonText: string;
    style?: React.CSSProperties;
}
const RegisterForm = ({ submitButtonText, style }: RegisterFormProps) => {
    const dispatch = useAppDispatch();
    const router = useRouter();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [portfolioSize, setPortfolioSize] = useState('0-9');
    const [showPassword, setShowPassword] = useState(false);
    const [referralCode, setReferralCode] = useState((router.query.referrer_id as string) || '');
    const formRef = useRef<HTMLFormElement>(null);
    const turnstileRef = useRef<TurnstileInstance>(null);

    useEffect(() => {
        analytics.track('Register Form: Viewed');
    }, []);

    const validateSignup = () => {
        if (name === '') {
            return false;
        }
        if (company === '') {
            return parseInt(portfolioSize) < 10;
        }
        if (email === '') {
            return false;
        }
        if (password === '') {
            return false;
        }
        return true;
    };

    const onFormSubmit: React.FormEventHandler = (e) => {
        e.preventDefault();
        const nameObj = humanname.parse(name);
        const formData = new FormData(formRef.current!);
        const token = formData.get('cf-turnstile-response') as string;
        const onFail = () => {
            turnstileRef.current?.reset();
        };

        dispatch(
            submitRegistration(
                {
                    'user[email]': email.trim(),
                    'user[password]': password.trim(),
                    'user[first_name]': nameObj.firstName,
                    'user[last_name]': nameObj.lastName,
                    'user[company]': company,
                    'user[portfolio_size]': portfolioSize.trim(),
                    'user[accepted_toa]': true,
                    'user[allow_marketing_emails]': true,
                    'user[default_pricing_tier_level]': parseInt((router.query.tier as string) || '0'),
                    referrer_id: referralCode,
                    'cf-turnstile-response': token,
                },
                onFail,
            ),
        );
    };

    const isSignUpDisabled = !validateSignup();
    return (
        <form ref={formRef} id="registration-form" onSubmit={isSignUpDisabled ? undefined : onFormSubmit} style={{ ...style }}>
            <Text mb={4} as="h3" textAlign="center" fontSize="4xl" fontWeight={600} lineHeight="normal" letterSpacing="tight">
                Book more rooms, earn more money, and save time.
            </Text>
            <Text as="p" textAlign="center">
                No credit card required.
            </Text>
            <Stack spacing={5}>
                <InputField label="Name">
                    <Input
                        size="lg"
                        type="text"
                        pr="4.5rem"
                        value={name}
                        placeholder="Your name"
                        onChange={(e) => setName(e.target.value)}
                        data-testid="input-register-name"
                    />
                </InputField>

                <InputField label="Email">
                    <Input
                        size="lg"
                        type="email"
                        pr="4.5rem"
                        placeholder="Enter email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        data-testid="input-register-email"
                    />
                </InputField>
                <InputField
                    label="Password"
                    subText="10+ characters, at least 1 upper, lowercase and special character"
                    tooltip="(10+ characters, at least 1 upper, lowercase and special character)">
                    <InputGroup size="md">
                        <Input
                            size="lg"
                            pr="4.5rem"
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            type={showPassword ? 'text' : 'password'}
                            placeholder="Enter password"
                            data-testid="input-register-password"
                        />
                        <InputRightElement width="4.5rem" h="full">
                            <Button
                                variant="filter"
                                h="1.75rem"
                                size="sm"
                                onClick={() => {
                                    setShowPassword(!showPassword);
                                }}>
                                {showPassword ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>
                </InputField>
                <PortfolioSize onChange={(v) => setPortfolioSize(v)} value={portfolioSize} />
                {parseInt(portfolioSize) >= 10 && (
                    <InputField label="Company">
                        <Input size="lg" type="text" pr="4.5rem" value={company} placeholder="Company name" onChange={(e) => setCompany(e.target.value)} />
                    </InputField>
                )}
                <InputField style={{ color: 'gray.400', fontWeight: 400 }} label="Referral code (optional)">
                    <Input size="lg" type="text" pr="4.5rem" placeholder="Enter code" onChange={(e) => setReferralCode(e.target.value)} value={referralCode} />
                </InputField>
            </Stack>
            <Button
                size="lg"
                rounded="md"
                mt="25px"
                width="100%"
                type="submit"
                disabled={isSignUpDisabled}
                letterSpacing="tight"
                data-testid="form-register-submit">
                {submitButtonText}
            </Button>
            <div
                style={{
                    fontSize: 12,
                    textAlign: 'center',
                    marginTop: 20,
                    lineHeight: 1.6,
                    padding: '0px 35px',
                    color: grayMedium,
                }}>
                By signing up, you agree to <Anchor href={termsOfUsePath()}>Wheelhouse's terms of use</Anchor> and{' '}
                <Anchor href={privacyPolicyPath()}>privacy policy</Anchor>.
            </div>
            <TurnstileToken ref={turnstileRef} />
        </form>
    );
};

export default RegisterForm;
