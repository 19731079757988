import React from 'react';
import SignupPageContainer from 'libs/global/components/SignupPageContainer';
import RequireLoggedOutWrapper from 'libs/global/components/RequireLoggedOutWrapper';
import RegisterHandler from 'libs/global/components/Site/RegisterHandler';

const Wrapper = RequireLoggedOutWrapper(SignupPageContainer);

const Register = () => {
    return (
        <Wrapper>
            <RegisterHandler />
        </Wrapper>
    );
};

export default Register;
