'use client';

import React from 'react';
import { Text } from '@chakra-ui/react';

const OnboardingHeader = ({ header, subheader }: { header?: React.ReactNode; subheader?: React.ReactNode }) => {
    return (
        <div>
            <Text color="gray.900" fontWeight="nomal" fontSize={['md', 'xl']}>
                {header}
            </Text>
            <Text color="gray.600">{subheader}</Text>
        </div>
    );
};

export default OnboardingHeader;
