import React from 'react';

interface Props {
    style?: React.CSSProperties;
    vertical?: boolean;
}

const Divider = ({ style, vertical }: Props) => {
    const dividerColor = 'solid 1px #e1e4ed';

    if (vertical) {
        return (
            <div
                style={{
                    height: '100%',
                    borderLeft: dividerColor,
                    width: 1,
                    ...style,
                }}
            />
        );
    }

    return (
        <div
            style={{
                width: '100%',
                borderTop: dividerColor,
                height: 1,
                ...style,
            }}
        />
    );
};

export default Divider;
