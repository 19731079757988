'use client';

import React from 'react';
import Anchor from 'libs/global/components/Anchor';
import { Heading, Text } from '@chakra-ui/react';
import { useIntercom } from 'libs/global/hooks/useIntercom';

interface Props {
    questions: [React.ReactNode, React.ReactNode][];
    hideLetUsKnow?: boolean;
}

const OnboardingFAQ = (props: Props) => {
    const showIntercom = useIntercom();
    return (
        <div className="ph3" style={{ maxWidth: 650, margin: 'auto' }}>
            <Heading size="xl" fontWeight="light" textAlign="center" mb={10}>
                Frequently Asked Questions
            </Heading>

            {props.questions.map(([question, answer], idx) => [
                <Heading key={`q${idx}`} size="md" fontWeight="medium" mb={4}>
                    {question}
                </Heading>,
                <Text key={`a${idx}`} mb={6}>
                    {answer}
                </Text>,
            ])}

            {!props.hideLetUsKnow && (
                <Text mt={4}>
                    <i>
                        If you have questions we didn't cover, we'd be happy{' '}
                        <Anchor onClick={() => showIntercom()} kind="primary">
                            {' '}
                            to answer them
                        </Anchor>
                        .
                    </i>
                </Text>
            )}
        </div>
    );
};

export default OnboardingFAQ;
