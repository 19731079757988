import React from 'react';

import OnboardingLayout from 'libs/global/components/OnboardingLayout';
import OnboardingHeader from 'libs/global/components/OnboardingHeader';
import OnboardingFAQ from 'libs/global/components/OnboardingFAQ';
import RegisterForm from 'libs/global/components/RegisterForm';

const QUESTIONS = [
    [
        'Why do I have to create a Wheelhouse account?',
        'For security purposes. When you connect to Wheelhouse with the account your listing are on, we want to make sure your information is protected. To make this happen, we require you to be authenticated within our application.',
    ],
    [
        'Is there any limit to the number of listings I can have?',
        "No, you can have as many listings in your Wheelhouse as you'd like. Depending on the number of listings you have in total, you may qualify for our custom enterprise pricing.",
    ],
] as [React.ReactNode, React.ReactNode][];

const RegisterHandler = () => {
    return (
        <OnboardingLayout
            header={<OnboardingHeader />}
            content={<RegisterForm submitButtonText="Get started for free" style={{ maxWidth: 440, margin: '0 auto' }} />}
            footer={<OnboardingFAQ questions={QUESTIONS} />}
        />
    );
};

export default RegisterHandler;
