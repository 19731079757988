import React from 'react';
import { useIntercom } from 'libs/global/hooks/useIntercom';
import Anchor from 'libs/global/components/Anchor';

const ChatAnchor = (props: React.ComponentProps<typeof Anchor>) => {
    const showIntercom = useIntercom();
    return <Anchor {...props} onClick={() => showIntercom()} />;
};

export default ChatAnchor;
